import order from '~/modules/tracking-system/routes/order';
import location from '~/modules/tracking-system/routes/location';
import stock from '~/modules/tracking-system/routes/stock';
import invoice from '~/modules/tracking-system/routes/invoice';
import barcode from '~/modules/tracking-system/routes/barcode';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/tracking-system',
        name: 'module.tracking-system',
        component: async () => import('../../../views/RouteViewWrapper.vue'),
        children: [
            ...order,
            ...invoice,
            ...location,
            ...stock,
            ...invoice,
            ...barcode,
        ],
    },
];

export default routes;
