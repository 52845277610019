import createModel, { defineModel } from '~/lib/model';

import type { Model, ModelDefinition } from '~/typings/model';
import type { BigMentionCode } from '~/typings/enums';

export interface BigMention extends Model {
    id: number;
    big_mention: BigMentionCode;
    name: string;
}

const bigMentionModel = createModel('bigmentions', defineModel<BigMention>(), {});

const useBigMention = (): ModelDefinition<typeof bigMentionModel> => bigMentionModel();

export default useBigMention;
