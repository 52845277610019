/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpResponseCode, Role } from './typings/enums';
import { getRouter } from './lib/router/RouterPipeline';
import useToast from './lib/hooks/toast';
import useHttp from './lib/http';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import type { User } from './models/User';
import type { HttpError } from './typings/http';
import type { AnyObject, KeyValueObject } from './typings/interfaces';
import type { RouteGuard } from './typings/third-party';
import type {
    AnyAsyncFunction,
    AnyFunction,
    i18nKey,
    LaravelExceptionResponse,
    LaravelExceptionStackTraceRecord,
} from './typings/types';

export const isObject = (value: unknown): value is AnyObject => {
    return Object.prototype.toString.call(value) === '[object Object]';
};

export const isFunction = (value: unknown): value is AnyFunction => {
    return Object.prototype.toString.call(value) === '[object Function]';
};

export const isAsyncFunction = (value: unknown): value is AnyAsyncFunction => {
    if (!isFunction(value)) {
        return false;
    }

    return value.constructor.name === 'AsyncFunction';
};

export const isString = (value: unknown): value is string => {
    return Object.prototype.toString.call(value) === '[object String]';
};

export const isDefined = <T>(value: T): value is NonNullable<T> => {
    return value !== null && value !== undefined;
};

export function isValidNumber(value: unknown): value is number {
    return typeof value === 'number' && !Number.isNaN(value);
}

export const isEmpty = (value: unknown): boolean => {
    if (!isDefined(value)) {
        return true;
    }

    if (isString(value)) {
        return (value as string).trim() === '';
    }

    if (Array.isArray(value)) {
        return (value as unknown[]).length === 0;
    }

    if (isObject(value)) {
        return Object.keys(value as AnyObject).length === 0;
    }

    return false;
};

export const url = (path?: string): string => {
    if (!path) {
        return window.location.origin;
    }

    return `${window.location.origin}/${path}`;
};

export const debounce = <F extends AnyFunction>(
    callback: F,
    delayInMs = 500): (...arguments_: Parameters<F>) => void => {
    let timeout: NodeJS.Timeout;

    return (...arguments_: Parameters<F>): void => {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
            callback(...arguments_);
        }, delayInMs);
    };
};

export const throttle = <F extends AnyFunction>(
    callback: F,
    delayInMs = 500,
): (...arguments_: Parameters<F>) => void => {
    let throttled = false;
    let queuedArguments: Parameters<F> | null;

    const timeoutFunction = (): void => {
        if (!queuedArguments) {
            throttled = false;
        } else {
            callback(...queuedArguments);
            queuedArguments = null;
            setTimeout(timeoutFunction, delayInMs);
        }
    };

    return (...arguments_: Parameters<F>): void => {
        if (throttled) {
            queuedArguments = arguments_;

            return;
        }

        callback(...arguments_);
        throttled = true;
        setTimeout(timeoutFunction, delayInMs);
    };
};

export const capitalize = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const sleep = async (durationInMs: number): Promise<unknown> => {
    return new Promise(resolve => {
        setTimeout(resolve, durationInMs);
    });
};

export const portalUrl = (path?: string): string => {
    const base = import.meta.env.VITE_WGM_COMMERCIAL_URL;

    if (!path) {
        return base;
    }

    const trimmedPath = path.replace(/(^\/+|\/+$)/gm, '');

    return `${base}/${trimmedPath}`;
};

export const isTouchScreen = (): boolean => {
    return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
};

export const defineRouteGuard = <G extends RouteGuard>(guard: G): G => guard;

export const isLaravelExceptionStackTrace = (value: unknown): value is LaravelExceptionStackTraceRecord[] => {
    if (!Array.isArray(value) || value.length === 0) {
        return false;
    }

    const firstRecord = value[0];

    if (!isObject(firstRecord)) {
        return false;
    }

    return firstRecord.class !== undefined
        && firstRecord.file !== undefined
        && firstRecord.function !== undefined
        && firstRecord.line !== undefined
        && firstRecord.type !== undefined;
};

export const isLaravelExceptionResponse = (
    error: unknown,
): error is HttpError<AxiosRequestConfig, AxiosError, LaravelExceptionResponse> => {
    if (!isObject(error) || !error.isHttpError || !isObject(error.response.data)) {
        return false;
    }

    const data = error.response.data;

    return data.exception !== undefined
        && data.file !== undefined
        && data.line !== undefined
        && data.message !== undefined;
};

export const fullUnref = <T>(value: T): T => {
    if (Array.isArray(value)) {
        return JSON.parse(JSON.stringify([...value]));
    }

    if (isObject(value)) {
        return JSON.parse(JSON.stringify({ ...value }));
    }

    return JSON.parse(JSON.stringify(value));
};

export const userRoles = (): Role[] => {
    return [
        Role.AccountManager,
        Role.Admin,
        Role.CustomerService,
        Role.Pharmacist,
    ];
};

export const caregiverRoles = (): Role[] => {
    return [
        Role.Physician,
        Role.PhysicianAssistant,
        Role.Physiotherapist,
        Role.Midwife,
        Role.Nurse,
        Role.PolyclinicEmployee,
    ];
};

export const patientRoles = (): Role[] => {
    return [
        Role.Patient,
    ];
};

export const trackingSystemManagementRoles = (): Role[] => {
    return [
        Role.CarePortalCustomerService,
        Role.CarePortalAccountManager,
        Role.CarePortalManager,
    ];
};

export const trackingSystemRoles = (): Role[] => {
    return [
        Role.CarePortalCustomerService,
        Role.CarePortalAccountManager,
        Role.CarePortalManager,
        Role.CarePortalUser,
        Role.CarePortalViewer,
    ];
};

export const uncategorizedRoles = (): Role[] => {
    return [
        Role.Developer,
        Role.Unknown,
    ];
};

export const isUser = (user: User): boolean => {
    for (const role of userRoles()) {
        if (user.roles.has(role)) {
            return true;
        }
    }

    return false;
};

export const isCaregiver = (user: User): boolean => {
    if (user.big_registrations.length === 0) {
        return false;
    }

    for (const role of caregiverRoles()) {
        if (user.roles.has(role)) {
            return true;
        }
    }

    return false;
};

export const isPatient = (user: User): boolean => {
    for (const role of patientRoles()) {
        if (user.roles.has(role)) {
            return true;
        }
    }

    return false;
};

export function requiresValidBigRegistration(role: Role): boolean {
    if (!caregiverRoles().includes(role)) {
        return false;
    }

    return role !== Role.PolyclinicEmployee;
}

export function canHaveBigRegistration(role: Role): boolean {
    if (requiresValidBigRegistration(role)) {
        return true;
    }

    switch (role) {
        case Role.CustomerService:
        case Role.Pharmacist:
            return true;
        default:
            return false;
    }
}

export const sortRolesByTranslatedName = (roles: Role[]): Role[] => {
    const { t: translate } = useI18n();

    return roles.sort((roleA, roleB) => {
        const roleATranslated = translate(`general.roles.${roleA}`);
        const roleBTranslated = translate(`general.roles.${roleB}`);

        return String(roleATranslated).localeCompare(String(roleBTranslated));
    });
};

export const sortByTranslation = (values: string[], i18nKeyGroup: string): string[] => {
    const { t: translate } = useI18n();

    return values.sort((valueA, valueB) => {
        const translatedA = translate(`${i18nKeyGroup}.${valueA}`);
        const translatedB = translate(`${i18nKeyGroup}.${valueB}`);

        return String(translatedA).localeCompare(String(translatedB));
    });
};

export const removeEmptyStringObjectEntries = (object: KeyValueObject<unknown>): KeyValueObject<unknown> => {
    return Object.fromEntries(Object.entries(object).filter(([, value]) => value !== ''));
};

export const randomNumber = (min: number, max: number): number => {
    return Math.floor((Math.random() * max) + min);
};

export const randomElement = <T>(array: T[]): T | null => {
    if (array.length === 0) {
        return null;
    }

    return array[randomNumber(0, array.length - 1)];
};

export const fakeV4Uuid = (): string => {
    const letters = ['a', 'b', 'c', 'd', 'e', 'f'];
    const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    const characters = [...letters, ...numbers];
    const v4Character = [8, 9, 'a', 'b'];
    const pattern = '########-fake-4###-%###-############';

    return [...pattern].map(item => {
        switch (item) {
            case '%':
                return randomElement(v4Character);
            case '#':
                return randomElement(characters);
            default:
                return item;
        }
    }).join('');
};

export const sortObjectAlphabetically = <O extends AnyObject>(object: O | string): O => {
    const sourceObject: AnyObject = isString(object) ? JSON.parse(object) : { ...object };
    const sortedObject: AnyObject = {};
    const sortedKeys = Object.keys(sourceObject).sort((first, second) => first.localeCompare(second));

    sortedKeys.forEach(key => {
        if (isObject(sourceObject[key])) {
            sortedObject[key] = sortObjectAlphabetically(sourceObject[key]);
        } else {
            sortedObject[key] = sourceObject[key];
        }
    });

    return sortedObject;
};

export const convertEnumToList = <O extends AnyObject>(object: O, translationPrefix: string): Array<{ key: i18nKey; value: string }> => {
    return Object.values(object).map(value => ({ key: `${translationPrefix}.${value}`, value }));
};

export function isProduction(): boolean {
    return import.meta.env.VITE_SERVER_ENVIRONMENT === 'production';
}

export function isTest(): boolean {
    return new Set(['test']).has(import.meta.env.MODE);
}

export function isMobileDevice(): boolean {
    return /android|iphone/i.test(navigator.userAgent);
}

export function isProfilePage(): boolean {
    const router = getRouter();
    const route = router.currentRoute.value;

    return route && 'name' in route && route.name === 'my-account';
}

export function assert(expression: unknown): asserts expression {
    if (!expression) {
        throw new Error('Assertion failed');
    }
}

export function handleError(error: unknown, developmentMessage: string): void {
    if (useHttp().isHttpError(error) && error.response.status === HttpResponseCode.InvalidInput) {
        return;
    }

    const _isProduction = isProduction();

    useToast({
        type: 'error',
        header: _isProduction
            ? undefined
            : '[Development Only]',
        context: _isProduction
            ? 'toasts.unidentified_error'
            : developmentMessage,
        timeout: false,
    });

    if (!_isProduction) {
        console.error(error);
    }
}
