import createModel, { defineModel } from '~/lib/model';
import type {
    Ambulant,
    AmbulantDuration,
    MedicalIndication,
    AmbulantDevice,
    SmokerRoommates,
    ComplaintSeverity,
    PAPDeviceSetting,
    PEPIMTAndAccessory,
} from '~/typings/enums';
import type { Model, ModelDefinition } from '~/typings/model';

import type { Uuid } from '~/typings/types';
import type { Medicine } from '~/models/Therapy';

export interface PatientInfo extends Model {
    user_uuid: Uuid;
    insurance: unknown; // tbd
    insurance_updated_at: string | null;
    smoker: boolean | null;
    bleomycin: boolean | null;
    smoker_roommates: SmokerRoommates | null;
    ambulant: Ambulant | null;
    ambulant_duration: AmbulantDuration | null;
    ambulant_device: AmbulantDevice | null;
    independent: boolean | null;
    diagnoses_comment: string;
    diagnoses: MedicalIndication[];
    pharmacy: string;
    pharmacy_city: string;
    medicine: Medicine[];
    pep_imt_and_accessories: PEPIMTAndAccessory | null;
    pep_imt_and_accessories_divergent: string | null;
    device_setting: PAPDeviceSetting | null;
    device_apap_min_pressure: number | null;
    device_apap_max_pressure: number | null;
    device_cpap_pressure: number | null;
    device_ipap_pressure: number | null;
    device_epap_pressure: number | null;
    device_pressure_buildup: number | null;
    device_start_pressure: number | null;
    measurement_apnea_hypopnea_index: number | null;
    measurement_apnea_index: number | null;
    measurement_desaturation_index: number | null;
    measurement_lowest_saturation: number | null;
    measurement_bmi: number | null;
    measurement_ahi_side: number | null;
    measurement_ahi_supine: number | null;
    measurement_supine_percentage: number | null;
    complaint_severity_irritability: ComplaintSeverity | null;
    complaint_severity_concentration: ComplaintSeverity | null;
    complaint_severity_mood_disorders: ComplaintSeverity | null;
    complaint_severity_hypersomnolence: ComplaintSeverity | null;
    complaint_severity_fatigue: ComplaintSeverity | null;
    complaint_severity_sleepiness: ComplaintSeverity | null;
    complaint_severity_regular_wakeups: ComplaintSeverity | null;
    complaint_severity_stiff_breathing: ComplaintSeverity | null;
    complaint_severity_unrefreshing_sleep: ComplaintSeverity | null;
    dosage_liters_daytime: number | undefined;
    dosage_liters_nighttime: number | undefined;
    dosage_liters_during_exercise: number | undefined;
    dosage_liters_incidental: number | undefined;
    dosage_hours_per_day: number | undefined;
    dosage_hours_incidental: number | undefined;
    suggested_dosage_liters_daytime: number | undefined;
    suggested_dosage_liters_nighttime: number | undefined;
    suggested_dosage_liters_during_exercise: number | undefined;
    suggested_dosage_liters_incidental: number | undefined;
    suggested_dosage_hours_per_day: number | undefined;
    suggested_dosage_hours_incidental: number | undefined;
}

const patientinfoModel = createModel('patientinfo', defineModel<PatientInfo>(), {});

const usePatientInfo = (): ModelDefinition<typeof patientinfoModel> => patientinfoModel();

export default usePatientInfo;
