import useHttp from '~/lib/http';
import createModel, { defineModel, modelArrayToMap } from '~/lib/model';
import { HttpResponseCode } from '~/typings/enums';
import type {
    Modules,
    PhoneNumberType,
    Gender,
    Role,
    UserStatus,
} from '~/typings/enums';
import type { Uuid } from '~/typings/types';
import type { Model, ModelDefinition } from '~/typings/model';
import type { CreateUserPayload, UpdateEmailPayload } from '~/typings/auth';
import type { BigMention } from './BigMention';
import type { BigRegistration } from './BigRegistration';

export interface UserSettings {
    is_subscribed: boolean;
}

export interface BaseUser extends Model {
    // DB columns
    user_status: UserStatus;
    first_name: string | null;
    insertion: string | null;
    last_name: string | null;
    email: string | null;
    secondary_email: string | null;
    email_verified_at: string | null;
    secondary_email_verified_at: string | null;
    gender: Gender | null;
    date_of_birth: string | null;
    social_security_number: string | null;
    iban: string | null;
    iban_holder: string | null;
    sap_id: string | null;
    caregroup_names: string[];
    settings: UserSettings;

    // Accessors && custom properties
    name: string | null;
    avatar_url: string | null;
    primary_phone_number: string | null;
}

export interface PhoneNumber {
    phone_type: PhoneNumberType;
    uuid: Uuid;
}

export interface RawUser extends BaseUser {
    // Relationships
    caregroup_uuids: Uuid[];
    caregroup_modules: Record<Uuid, Modules[]>;
    address_uuid: Uuid | null;
    phonenumber_uuids: PhoneNumber[];
    contact_uuids: Uuid[];
    patient_info_uuid: Uuid | null;
    big_mentions: BigMention[];
    big_registrations: BigRegistration[];
    roles: Role[];
}

export interface User extends BaseUser {
    // Relationships
    caregroup_uuids: Uuid[];
    address_uuid: Uuid | null;
    phonenumber_uuids: PhoneNumber[];
    contact_uuids: Uuid[];
    patient_info_uuid: Uuid | null;
    roles: Set<Role>;
    big_mentions: BigMention[];
    big_registrations: BigRegistration[];
}

const userModel = createModel('users', defineModel<RawUser, User, CreateUserPayload>(), {
    getters: {
        collection(state) {
            const models = [...state.models.values()].map(model => {
                return {
                    ...model,
                    roles: new Set(model.roles),
                };
            });

            return modelArrayToMap(models);
        },
    },
    actions: {
        async resetPassword(uuid: Uuid) {
            return useHttp().post<{ status: string }>(`users/reset-password/${uuid}`);
        },
        async updateEmail(uuid: Uuid, payload: UpdateEmailPayload) {
            return useHttp().patch<User>(`users/update-email/${uuid}`, payload).then(response => {
                const user = this.models.get(uuid);

                if (user) {
                    user.email = payload.email ? payload.email : response.email;
                    user.secondary_email = payload.secondary_email ? payload.secondary_email : response.secondary_email;
                }
            });
        },
        async updatePermissions(uuid: Uuid, permissionIds: number[]) {
            return useHttp().patch(`users/permissions/${uuid}`, {
                permissions: permissionIds,
            });
        },
        async updateRoles(uuid: Uuid, roleIds: number[]) {
            return useHttp().patch(`users/roles/${uuid}`, {
                roles: roleIds,
            });
        },
        async updateUserCaregroup(uuid: Uuid, caregroupUuid: Uuid) {
            return useHttp().post<RawUser>(`users/caregroup/${uuid}`, {
                caregroup_uuid: caregroupUuid,
            }).then(data => {
                const user = this.models.get(uuid);

                if (user) {
                    this.models.set(uuid, data);
                }
            });
        },
        async destroyUserCaregroup(uuid: Uuid, caregroupUuid?: Uuid) {
            const http = useHttp();
            const queryParameters = caregroupUuid ? http.stringifyParameters({
                caregroup_uuid: caregroupUuid,
            }) : '';

            return useHttp().delete<RawUser>(`users/caregroup/${uuid}${queryParameters}`)
                .then(data => {
                    const user = this.models.get(uuid);

                    if (user) {
                        this.models.set(uuid, data);
                    }
                });
        },
        async createPatient(socialSecurityNumber: string, dateOfBirth: string, caregroupUuid?: Uuid) {
            let $payload = { social_security_number: socialSecurityNumber, date_of_birth: dateOfBirth };
            $payload = Object.assign($payload, { caregroup_uuid: caregroupUuid });

            return useHttp().post<{ status: number; data: RawUser }>('/patients', $payload, { unaltered: true })
                .then(data => {
                    this.models.set(data.data.uuid, data.data);

                    return data;
                });
        },
        async approveUser(userUuid: string, userStatus: UserStatus.Approved | UserStatus.Rejected) {
            return useHttp().patch(`users/approve/${userUuid}`, { user_status: userStatus })
                .then(() => {
                    const model = this.models.get(userUuid);

                    if (model) {
                        model.user_status = userStatus;
                    }
                });
        },
        async addPatientToCaregroup(socialSecurityNumber: string, dateOfBirth: string, caregroupUuid: Uuid) {
            const { status, data: patient } = await this.createPatient(socialSecurityNumber, dateOfBirth, caregroupUuid);

            if (status === HttpResponseCode.OK) {
                return this.updateUserCaregroup(patient.uuid, caregroupUuid);
            }

            return Promise.resolve();
        },
    },
});

const useUser = (): ModelDefinition<typeof userModel> => userModel();

export default useUser;
