import { Permission, Role } from '~/typings/enums';
import { caregiverRoles, defineRouteGuard, isPatient } from '~/helpers';
import error from './error';
import development from './development';
import auth from './auth';
import therapy from './therapy';
import useAuth from '~/stores/Auth';
import modules from '~/routes/modules';
import type { RouteLocationRaw, RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'dashboard',
        component: async () => import('../views/Dashboard.vue'),
        meta: {
            title: 'Dashboard',
        },
    },
    {
        path: '/users',
        name: 'users',
        component: async () => import('../views/RouteViewWrapper.vue'),
        redirect: {
            name: 'users.overview',
        },
        children: [
            {
                path: '',
                name: 'users.overview',
                component: async () => import('../views/Users/UsersOverview.vue'),
                meta: {
                    title: 'Gebruikersoverzicht',
                    permission: [
                        Permission.Global_UserManagement_Overview,
                    ],
                },
            },
            {
                path: ':uuid',
                name: 'users.show',
                component: async () => import('../views/Users/ShowUser.vue'),
                meta: {
                    title: 'Bekijk gebruiker',
                    permission: [
                        Permission.Global_UserManagement_Overview,
                        Permission.Global_UserManagement_ValidateBig,
                    ],
                },
            },
            {
                path: 'create',
                name: 'users.create',
                component: async () => import('../views/Users/CreateUser.vue'),
                meta: {
                    title: 'Gebruiker aanmaken',
                    permission: [
                        Permission.Global_UserManagement_Create,
                    ],
                },
            },
            {
                path: 'validate',
                name: 'users.validate',
                component: async () => import('../views/Users/ValidateRegistration.vue'),
                meta: {
                    title: 'Gebruikers valideren',
                    permission: [
                        Permission.Global_UserManagement_ValidateBig,
                    ],
                },
            },
        ],
    },
    {
        path: '/patients',
        name: 'patients',
        component: async () => import('../views/RouteViewWrapper.vue'),
        redirect: (): RouteLocationRaw => {
            const authStore = useAuth();

            if (
                authStore.user?.caregroup_uuids.length === 1
                && authStore.hasAnyPermissionTo(Permission.Global_CaregroupManagement_View)
            ) {
                return {
                    name: 'caregroups.show',
                    params: {
                        uuid: authStore.user.caregroup_uuids[0],
                    },
                };
            }

            if (authStore.hasAnyPermissionTo(Permission.Global_CaregroupManagement_Overview)) {
                return {
                    name: 'caregroups.overview',
                };
            }

            return {
                name: 'dashboard',
            };
        },
        meta: {
            guard: defineRouteGuard((user, destination, { hasAnyPermissionTo, hasAnyRole }) => {
                if (hasAnyPermissionTo([Permission.Global_PatientManagement_Overview])) {
                    return true;
                }

                if (hasAnyRole([
                    Role.AccountManager,
                    ...caregiverRoles(),
                ]) && destination.params?.uuid !== undefined) {
                    return true;
                }

                if (hasAnyRole([Role.CustomerService]) && user.caregroup_uuids.length > 0 && destination.params?.uuid !== undefined) {
                    return true;
                }

                return false;
            }),
        },
        children: [
            {
                path: 'overview',
                name: 'patients.overview',
                component: async () => import('../views/Patients/PatientsOverview.vue'),
                meta: {
                    title: 'Patiëntenoverzicht',
                    guard: defineRouteGuard((user, destination, { hasAnyPermissionTo, hasAnyRole }) => {
                        return hasAnyRole([Role.CustomerService]) || hasAnyPermissionTo([Permission.Global_PatientManagement_Overview]);
                    }),
                },
            },
            {
                path: ':uuid',
                name: 'patients.show',
                component: async () => import('../views/Users/ShowUser.vue'),
                meta: {
                    title: 'Bekijk patiënt',
                    // Route guard is done within the view because we need the patients caregroup
                },
            },
        ],
    },
    {
        path: '/caregroups',
        name: 'caregroups',
        component: async () => import('../views/RouteViewWrapper.vue'),
        redirect: {
            name: 'caregroups.overview',
        },
        meta: {
            guard: defineRouteGuard(user => {
                return !isPatient(user);
            }),
        },
        children: [
            {
                path: '',
                name: 'caregroups.overview',
                component: async () => import('../views/Caregroups/CaregroupsOverview.vue'),
                meta: {
                    title: 'Zorggroepen overzicht',
                    guard: defineRouteGuard((_user, _destination, { hasAnyPermissionTo }) => {
                        return hasAnyPermissionTo(Permission.Global_CaregroupManagement_Overview);
                    }),
                },
            },
            {
                path: ':uuid',
                name: 'caregroups.show',
                component: async () => import('../views/Caregroups/ShowCaregroup.vue'),
                meta: {
                    title: 'Zorggroep bekijken',
                    guard: defineRouteGuard((user, destination, { hasAnyPermissionTo }) => {
                        const caregroupUuid = destination.params?.uuid as string | undefined;

                        return hasAnyPermissionTo(Permission.Global_CaregroupManagement_View) || user.caregroup_uuids.includes(caregroupUuid ?? '');
                    }),
                },
            },
        ],
    },
    {
        path: '/counter-reading',
        name: 'counter-reading',
        component: async () => import('../views/RouteViewWrapper.vue'),
        redirect: {
            name: 'counter-reading.overview',
        },
        children: [
            {
                path: '',
                name: 'counter-reading.overview',
                component: async () => import('../views/CounterReading/CounterReadingOverview.vue'),
                meta: {
                    title: 'Tellerstanden',
                    permission: [
                        Permission.Portal_CounterReading_View,
                    ],
                },
            },
            {
                path: 'create',
                name: 'counter-reading.create',
                component: async () => import('../views/CounterReading/CreateCounterReading.vue'),
                meta: {
                    title: 'Doorgeven',
                    permission: [
                        Permission.Portal_CounterReading_Insert,
                    ],
                },
            },
        ],
    },
    {
        path: '/my-account',
        name: 'my-account',
        component: async () => import('../views/Users/AuthUserAccountManagement.vue'),
        meta: {
            title: 'Mijn account',
        },
    },

    ...auth,
    ...development,
    ...therapy,
    ...modules,
    ...error, // should always be last in the list (includes a catch-all for the 404 page)
];

export default routes;
