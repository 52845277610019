import { Permission } from '~/typings/enums';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: 'barcode',
        name: 'module.tracking-system.barcodes',
        component: async () => import('../views/Barcode/Overview.vue'),
        meta: {
            title: 'Barcodes',
            permission: [
                Permission.Module_TrackingSystem_Barcodes_View,
            ],
        },
    },
    {
        path: 'barcode/:barcode',
        name: 'module.tracking-system.barcode',
        component: async () => import('../views/Barcode/Detail.vue'),
        meta: {
            title: 'Barcodes',
            permission: [
                Permission.Module_TrackingSystem_Barcodes_View,
            ],
        },
    },
];

export default routes;
